#projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: white;
  padding-top: 7vh;
  margin: 5vw;  
}


#my-projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80vw;
  align-items:  center;
  justify-content: space-evenly;
}

.projects-title {
  display: flex;
  justify-content: center;
  margin: 0 5vw;
  margin-bottom: 6vh;
  font-size: 4rem;
  width: 70vw;
}

.project-img {
  width: 24vw;
  z-index: 1;
}


.project {
  padding: 2vh;
  margin: 1vh;
  padding-bottom: 1vh;
  background-color: rgba(115, 2, 2, 0.3);
}

.image-and-desc {
  position: relative;
}

.image-desc {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  line-height: 1.6;
  background-color: rgba(0, 0, 0, 0.8);
  letter-spacing: 2px;
  font-size: 1rem;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  opacity: 0;
  transform-origin: right;
}


.image-desc:hover {
  animation: slide 0.5s 1 forwards ease-out;
  opacity: 1;
}


@keyframes slide {
  from {
    transform: translateX(0, 0);
    opacity: 0;
    overflow-x: hidden;
  }
  
  
  to {
    transform: translateX(100%, 50%);
    opacity: 1;
    overflow-x: hidden;
  }
}


.buttons {
  text-align: center;
  margin-top: 1vh;
}


@media only screen and (max-width: 428px) {
  #projects {
    padding-top: 55px;
    margin: 0;
  }

  .projects-title {
    font-size: 50px;
  }


  .project-img {
    width: 60vw;
    justify-self: center;
  }

  .image-desc {
    display: none;
    width: 60vw;
    height: 11vh;
  }

  .buttons {
    font-size: 10px;
  }

  .project {
    width: 60vw;
  }

}

@media only screen and (min-width: 2560px) and (min-height: 1440px) {
  .projects-title {
    font-size: 6rem;
  }
}