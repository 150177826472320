.nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.875rem;
  position: fixed;
  width: 100vw;
  background-color: black;
  top: 0;
  z-index: 1;
}



button.links {
  border-style: none;
  background-color: inherit;
}


.links {
  position: relative;
  color: white;
  text-decoration: none;
}



.links::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}


.links:hover::after {
  transform: scaleX(1);
}

#login-button {
  color: black;
}


@media only screen and (max-width: 428px) {
  .nav {
    font-size: 12px;
    height: 50px;
    min-width: 100%;
  }

  .home-img {
    display: flex;
    align-self: center;
    max-width:25px;
  }
}