.button {
  text-decoration: none;
  margin: 10px;
  font-size: 20px;
  padding: 4px;
}

.button:hover {
  transition: all 0.2s ease-in;
  background:#730202;
  background-size: 200% 200%;
  background-position: bottom right;
  color: white
}


@media only screen and (max-width: 428px) {
  .button {
    font-size: 15px;
    background:#730202;
    color: white;
  }
}