.skill-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
  font-size: 36px;
  color: white;
}


.skill-title {
  font-size: 100px;
  justify-self: center;
}

.skill-input {
  margin-bottom: 10px;
  font-size: 22px;
  width: 20vw;
  padding: 10px;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}

.skill-label {
  margin: 10px 0 10px 0;
}

.skill-label-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.skill-edit-form {
  display: flex;
  flex-direction: column;
  max-width: 50vw; 
}

.skill-edit-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.skill-edit-button {
  margin: 10px;
}

.error-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
}
.skills-error {
  color: white;
  font-size: 6vw;
}