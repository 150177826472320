.edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 36px;
  min-height: 100vh;
  min-width: 100vw;
}

#desc {
  resize:none;
  height: 15vh;
}


.edit-title {
  display: flex;
  align-self: center;
  font-size: 100px;
  margin-top: 8vh;
}
.edit-form {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
}
.edit-label-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.edit-input {
  width: 45vw;
  margin: 6px;
  padding-left: 2px;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}


.edit-label {
  margin: 5px 0;
  margin-left: 10px;
}


.edit-button {
  display: flex;
  align-self: center;
  margin: 6px;
}


@media only screen and (max-width: 428px) {
  .edit {
    font-size: 22px;
  }

  .edit-title {
    margin: 0;
    margin-bottom: 30px;
    font-size: 50px;
  }

  .edit-input {
    width: 80vw;
  }

  .edit-button {
    margin: 10px;
    width: 50vw;
    justify-content: center;

  }
}