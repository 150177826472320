.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: white;
  font-size: 3rem;
}

.contact-card {
  background-color: rgba(115, 2, 2, 0.3);
  margin: 10px;
}

.contact-title {
  justify-self: flex-start;
  font-size: 8rem;
  margin-bottom: 20px;
}



@media only screen and (max-width: 428px) {
  .contact {
    display: flex;
    width: 50vw;
    font-size: 20px;
  }
}