.add {
  color:white;
  display: flex;
  flex-direction: column;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
}

.add-title {
  display: flex;
  align-self: center;
  font-size: 100px;
  margin-top: 8vh;
}

.add-label-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
}

.add-label {
  margin: 10px 0;
}

.add-input {
  width: 40vw;
  background-color: lightgray;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}

.add-button {
  margin: 15px;
  align-self: center;
}

#desc {
  resize: none;
  font-size: 1.5rem;
}


.not-logged-in {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
  font-size: 4rem;
}


@media only screen and (max-width: 428px) {
  .add {
    font-size: 22px;
  }

  .add-title {
    font-size: 50px;
    margin-bottom: 20px;
  }

  .add-input {
    width: 70vw;
  }

  .add-label-input {
    margin: 3px;
  }
}