#landing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  max-width: 100vw;
  color: white;
  font-size: 7vw;
  padding-left: 50px;
}


.greeting {
  animation: span 0.5s 1s forwards;
  opacity: 0;
}

@keyframes span {
  from {
    opacity: 0;
  } 


  to {
    opacity: 1;
  }
  
}

.greeting1 {
  animation: greeting 0.5s 2s forwards;
  opacity: 0;
}




@keyframes greeting {
  from {
    opacity: 0;
  } 


  to {
    opacity: 1;
  }
  
}




@media only screen and (max-width: 428px) {
  #landing {
    margin: 0 auto;
    padding-left: 20px;
  }
  
  
  .intro {
    font-size: 3.5rem
  }
}




