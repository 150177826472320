#about-me{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: white;
    margin: 2vh 5vw;
}


.about-me {
  font-size: 4rem;
  margin-bottom: 2vh;
}


.brand-statement-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 50vh;
}


.brand-statement {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50vw;
  font-size: 32px;
  margin: 0 auto;
  line-height: 1.3;
  padding: 20px;
}



.skill-name {
  font-size: 1.5rem;
}

.skills-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50vw;
  margin-top: 3vh;
}
.my-skills {
  font-size: 4rem;
  margin: 2vh auto;
}
.skills {
  width: 6.5vw;
  margin: .6vw;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}

.skill-img-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 428px) {

  #about-me {
    margin: 0;
  }



  .about-me {
    font-size: 50px;
    justify-self: center;
    align-self: center;
    margin: 0;
  }

  .brand-statement {
    font-size: 1.5rem;
    max-width: 100vw;
    margin-top: -6vh;
  }
  
  .brand-statement-outer {
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .skills-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    max-width: 100vw;
    min-height: 100vh;
  }

  .my-skills {
    text-align: center;
    font-size: 50px;
    }

  .skills {
    width: 40px;
  }

  .skills-container {
    justify-content: center;
    margin: 0;
  }

  .skill-img-container {
    margin: 10px;
  }
}

@media only screen and (min-width: 2560px) and (min-height: 1440px) {
  .about-me{
    font-size: 7rem;
  }

  .my-skills {
    font-size: 3rem;
  }
}