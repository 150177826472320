/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

#about-me{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: white;
    margin: 2vh 5vw;
}


.about-me {
  font-size: 4rem;
  margin-bottom: 2vh;
}


.brand-statement-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  max-height: 50vh;
}


.brand-statement {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50vw;
  font-size: 32px;
  margin: 0 auto;
  line-height: 1.3;
  padding: 20px;
}



.skill-name {
  font-size: 1.5rem;
}

.skills-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50vw;
  margin-top: 3vh;
}
.my-skills {
  font-size: 4rem;
  margin: 2vh auto;
}
.skills {
  width: 6.5vw;
  margin: .6vw;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}

.skill-img-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 428px) {

  #about-me {
    margin: 0;
  }



  .about-me {
    font-size: 50px;
    justify-self: center;
    align-self: center;
    margin: 0;
  }

  .brand-statement {
    font-size: 1.5rem;
    max-width: 100vw;
    margin-top: -6vh;
  }
  
  .brand-statement-outer {
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .skills-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    max-width: 100vw;
    min-height: 100vh;
  }

  .my-skills {
    text-align: center;
    font-size: 50px;
    }

  .skills {
    width: 40px;
  }

  .skills-container {
    justify-content: center;
    margin: 0;
  }

  .skill-img-container {
    margin: 10px;
  }
}

@media only screen and (min-width: 2560px) and (min-height: 1440px) {
  .about-me{
    font-size: 7rem;
  }

  .my-skills {
    font-size: 3rem;
  }
}
.button {
  text-decoration: none;
  margin: 10px;
  font-size: 20px;
  padding: 4px;
}

.button:hover {
  transition: all 0.2s ease-in;
  background:#730202;
  background-size: 200% 200%;
  background-position: bottom right;
  color: white
}


@media only screen and (max-width: 428px) {
  .button {
    font-size: 15px;
    background:#730202;
    color: white;
  }
}
#projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: white;
  padding-top: 7vh;
  margin: 5vw;  
}


#my-projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80vw;
  align-items:  center;
  justify-content: space-evenly;
}

.projects-title {
  display: flex;
  justify-content: center;
  margin: 0 5vw;
  margin-bottom: 6vh;
  font-size: 4rem;
  width: 70vw;
}

.project-img {
  width: 24vw;
  z-index: 1;
}


.project {
  padding: 2vh;
  margin: 1vh;
  padding-bottom: 1vh;
  background-color: rgba(115, 2, 2, 0.3);
}

.image-and-desc {
  position: relative;
}

.image-desc {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  line-height: 1.6;
  background-color: rgba(0, 0, 0, 0.8);
  letter-spacing: 2px;
  font-size: 1rem;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  opacity: 0;
  -webkit-transform-origin: right;
          transform-origin: right;
}


.image-desc:hover {
  -webkit-animation: slide 0.5s 1 forwards ease-out;
          animation: slide 0.5s 1 forwards ease-out;
  opacity: 1;
}


@-webkit-keyframes slide {
  from {
    -webkit-transform: translateX(0, 0);
            transform: translateX(0, 0);
    opacity: 0;
    overflow-x: hidden;
  }
  
  
  to {
    -webkit-transform: translateX(100%, 50%);
            transform: translateX(100%, 50%);
    opacity: 1;
    overflow-x: hidden;
  }
}


@keyframes slide {
  from {
    -webkit-transform: translateX(0, 0);
            transform: translateX(0, 0);
    opacity: 0;
    overflow-x: hidden;
  }
  
  
  to {
    -webkit-transform: translateX(100%, 50%);
            transform: translateX(100%, 50%);
    opacity: 1;
    overflow-x: hidden;
  }
}


.buttons {
  text-align: center;
  margin-top: 1vh;
}


@media only screen and (max-width: 428px) {
  #projects {
    padding-top: 55px;
    margin: 0;
  }

  .projects-title {
    font-size: 50px;
  }


  .project-img {
    width: 60vw;
    justify-self: center;
  }

  .image-desc {
    display: none;
    width: 60vw;
    height: 11vh;
  }

  .buttons {
    font-size: 10px;
  }

  .project {
    width: 60vw;
  }

}

@media only screen and (min-width: 2560px) and (min-height: 1440px) {
  .projects-title {
    font-size: 6rem;
  }
}
#contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  min-height: 100vh;
  max-width: 100vw;
  color: white;
  font-size: 2.5rem;
}

#contact-me {
  font-size: 4rem;
  margin-bottom: 6vh;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  width: 50vw;
  height: 50vh;
}

#message {
  height: 20vh;
  font-size: 1.5rem;
}

.inputs {
  background-color:lightgrey;
  margin: 10px;
  width: 30vw;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}


.labels {
  display: flex;
  justify-content: flex-start;
  margin: 8px 10px;
}

.label-input {
  margin: 20px;
}


@media only screen and (max-width: 428px) {
  #contact {
    margin-top: 50px;
    font-size: 36px;
  }

  #contact-me {
    font-size: 2.5rem;
  }

  .inputs {
    width: 75vw;
  }
  
  .labels {
    font-size: 25px;
  }


  .contact-button {
    background:#730202;
    color: white;
    font-size: 1.5rem;
  }

}

@media only screen and (min-width: 2560px) and (min-height: 1440px) {
  #contact {
    font-size: 4rem
  }
}
#landing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  max-width: 100vw;
  color: white;
  font-size: 7vw;
  padding-left: 50px;
}


.greeting {
  -webkit-animation: span 0.5s 1s forwards;
          animation: span 0.5s 1s forwards;
  opacity: 0;
}

@-webkit-keyframes span {
  from {
    opacity: 0;
  } 


  to {
    opacity: 1;
  }
  
}

@keyframes span {
  from {
    opacity: 0;
  } 


  to {
    opacity: 1;
  }
  
}

.greeting1 {
  -webkit-animation: greeting 0.5s 2s forwards;
          animation: greeting 0.5s 2s forwards;
  opacity: 0;
}




@-webkit-keyframes greeting {
  from {
    opacity: 0;
  } 


  to {
    opacity: 1;
  }
  
}




@keyframes greeting {
  from {
    opacity: 0;
  } 


  to {
    opacity: 1;
  }
  
}




@media only screen and (max-width: 428px) {
  #landing {
    margin: 0 auto;
    padding-left: 20px;
  }
  
  
  .intro {
    font-size: 3.5rem
  }
}





.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
}

.image {
  width: 5vw;
  margin: 10px;
  -webkit-filter: invert();
          filter: invert();
}

.mail {
  width: 7vw;
  -webkit-filter: invert();
          filter: invert()
}

@media only screen and (max-width: 428px) {
  .image {
    width: 20vw;
    -webkit-filter: none;
            filter: none;
  }

  .mail {
    width: 28vw;
    -webkit-filter: none;
            filter: none;
  }

  .footer {
    background-color: white;
  }
}
.home {
  object-fit: cover;
}
.login {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 46px;
  min-width: 100vw;
  min-height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}

.login-title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-top:10vh;
  font-size: 100px;
}


.login-label-input {
  display: flex;
  flex-direction: column;
  margin: 10px;
}


.login-label {
  margin: 15px 0;
}


@media only screen and (max-width: 428px) {
  .login-title {
    margin-top: 80px;
    padding: 0;
    font-size: 50px;
  }

  .login {
    margin: 0;
    width:20vw;
    font-size: 20px;
  }


}

.add {
  color:white;
  display: flex;
  flex-direction: column;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
}

.add-title {
  display: flex;
  align-self: center;
  font-size: 100px;
  margin-top: 8vh;
}

.add-label-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
}

.add-label {
  margin: 10px 0;
}

.add-input {
  width: 40vw;
  background-color: lightgray;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}

.add-button {
  margin: 15px;
  align-self: center;
}

#desc {
  resize: none;
  font-size: 1.5rem;
}


.not-logged-in {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
  font-size: 4rem;
}


@media only screen and (max-width: 428px) {
  .add {
    font-size: 22px;
  }

  .add-title {
    font-size: 50px;
    margin-bottom: 20px;
  }

  .add-input {
    width: 70vw;
  }

  .add-label-input {
    margin: 3px;
  }
}
.edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 36px;
  min-height: 100vh;
  min-width: 100vw;
}

#desc {
  resize:none;
  height: 15vh;
}


.edit-title {
  display: flex;
  align-self: center;
  font-size: 100px;
  margin-top: 8vh;
}
.edit-form {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
}
.edit-label-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.edit-input {
  width: 45vw;
  margin: 6px;
  padding-left: 2px;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}


.edit-label {
  margin: 5px 0;
  margin-left: 10px;
}


.edit-button {
  display: flex;
  align-self: center;
  margin: 6px;
}


@media only screen and (max-width: 428px) {
  .edit {
    font-size: 22px;
  }

  .edit-title {
    margin: 0;
    margin-bottom: 30px;
    font-size: 50px;
  }

  .edit-input {
    width: 80vw;
  }

  .edit-button {
    margin: 10px;
    width: 50vw;
    justify-content: center;

  }
}
.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: white;
  font-size: 3rem;
}

.contact-card {
  background-color: rgba(115, 2, 2, 0.3);
  margin: 10px;
}

.contact-title {
  justify-self: flex-start;
  font-size: 8rem;
  margin-bottom: 20px;
}



@media only screen and (max-width: 428px) {
  .contact {
    display: flex;
    width: 50vw;
    font-size: 20px;
  }
}
.nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.875rem;
  position: fixed;
  width: 100vw;
  background-color: black;
  top: 0;
  z-index: 1;
}



button.links {
  border-style: none;
  background-color: inherit;
}


.links {
  position: relative;
  color: white;
  text-decoration: none;
}



.links::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}


.links:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

#login-button {
  color: black;
}


@media only screen and (max-width: 428px) {
  .nav {
    font-size: 12px;
    height: 50px;
    min-width: 100%;
  }

  .home-img {
    display: flex;
    align-self: center;
    max-width:25px;
  }
}
.skill-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
  font-size: 36px;
  color: white;
}


.skill-title {
  font-size: 100px;
  justify-self: center;
}

.skill-input {
  margin-bottom: 10px;
  font-size: 22px;
  width: 20vw;
  padding: 10px;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}

.skill-label {
  margin: 10px 0 10px 0;
}

.skill-label-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.skill-edit-form {
  display: flex;
  flex-direction: column;
  max-width: 50vw; 
}

.skill-edit-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.skill-edit-button {
  margin: 10px;
}

.error-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
}
.skills-error {
  color: white;
  font-size: 6vw;
}
.skill-add {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 36px;
  max-width: 100vw;
  min-height: 100vh;
}

.skill-add-label-input {
  display: flex;
  flex-direction: column;
}

.skill-add-label {
  margin: 20px 0;
}

.skill-add-input {
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}

.skill-add-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill-add-button {
  margin: 20px 0;
  justify-self: center;
}

.skill-add-title {
  font-size: 100px;
  margin-bottom: 20px;
}
html {
  background-image: url(/static/media/background.18ee546b.jpeg);
  background-color: black;
  background-repeat: repeat;
  background-size: cover;
  scrollbar-width: none; 
  -ms-overflow-style: none;  
  scroll-behavior:smooth;
  font-family: 'Playfair Display', serif;
}


*::-webkit-scrollbar {
  display: none;
}






