.skill-add {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 36px;
  max-width: 100vw;
  min-height: 100vh;
}

.skill-add-label-input {
  display: flex;
  flex-direction: column;
}

.skill-add-label {
  margin: 20px 0;
}

.skill-add-input {
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}

.skill-add-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill-add-button {
  margin: 20px 0;
  justify-self: center;
}

.skill-add-title {
  font-size: 100px;
  margin-bottom: 20px;
}