#contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  min-height: 100vh;
  max-width: 100vw;
  color: white;
  font-size: 2.5rem;
}

#contact-me {
  font-size: 4rem;
  margin-bottom: 6vh;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  width: 50vw;
  height: 50vh;
}

#message {
  height: 20vh;
  font-size: 1.5rem;
}

.inputs {
  background-color:lightgrey;
  margin: 10px;
  width: 30vw;
  box-shadow: 5px 10px 10px rgb(255 255 255 / 30%), 5px 10px 10px rgb(255 255 255 / 22%);
}


.labels {
  display: flex;
  justify-content: flex-start;
  margin: 8px 10px;
}

.label-input {
  margin: 20px;
}


@media only screen and (max-width: 428px) {
  #contact {
    margin-top: 50px;
    font-size: 36px;
  }

  #contact-me {
    font-size: 2.5rem;
  }

  .inputs {
    width: 75vw;
  }
  
  .labels {
    font-size: 25px;
  }


  .contact-button {
    background:#730202;
    color: white;
    font-size: 1.5rem;
  }

}

@media only screen and (min-width: 2560px) and (min-height: 1440px) {
  #contact {
    font-size: 4rem
  }
}