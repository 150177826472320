.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
}

.image {
  width: 5vw;
  margin: 10px;
  filter: invert();
}

.mail {
  width: 7vw;
  filter: invert()
}

@media only screen and (max-width: 428px) {
  .image {
    width: 20vw;
    filter: none;
  }

  .mail {
    width: 28vw;
    filter: none;
  }

  .footer {
    background-color: white;
  }
}