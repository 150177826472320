.login {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 46px;
  min-width: 100vw;
  min-height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}

.login-title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-top:10vh;
  font-size: 100px;
}


.login-label-input {
  display: flex;
  flex-direction: column;
  margin: 10px;
}


.login-label {
  margin: 15px 0;
}


@media only screen and (max-width: 428px) {
  .login-title {
    margin-top: 80px;
    padding: 0;
    font-size: 50px;
  }

  .login {
    margin: 0;
    width:20vw;
    font-size: 20px;
  }


}
