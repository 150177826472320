html {
  background-image: url("./background.jpeg");
  background-color: black;
  background-repeat: repeat;
  background-size: cover;
  scrollbar-width: none; 
  -ms-overflow-style: none;  
  scroll-behavior:smooth;
  font-family: 'Playfair Display', serif;
}


*::-webkit-scrollbar {
  display: none;
}





